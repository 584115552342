.App {
    font-family: "Open Sans", Verdana, Helvetica, sans-serif;
}

.MuiTableCell-root {
    padding: 9.5px !important;
    padding-left: 30px !important;
    font-family: "Open Sans", Verdana, Helvetica, sans-serif !important;
}

.MuiTypography-body1 {
    font-family: "Open Sans", Verdana, Helvetica, sans-serif !important;
    line-height: 1.43 !important;
}

.MuiListItem-root {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pLeft {
    padding-left: 13px;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.makeStyles-price-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
